import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import axios from 'axios'
import { Button } from 'reactstrap'
import Cookies from 'universal-cookie'
import DenverEvent from './../../assets/images/event_denver.jpeg'
import BrooklynEvent from './../../assets/images/event_brooklyn.jpg'
import { HOSTNAME } from '../config'

const cookies = new Cookies()

let initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  err: {},
  resMessage: '',
  resType: '',
  isLoading: false,
  tickets: [],
  user: cookies.get('user'),
  userUid: null,
  showModal: false,
  selectedTicketId: null,
}

class Events extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    if (!this.state.user) {
      navigate('/login')
      return
    }
    const user = this.state.user
    user.phone = user.phoneNumber
    axios
      .post(process.env.GATSBY_RAILS_API_URL + '/users/uid', user)
      .then(uidRes => {
        this.setState({ userUid: uidRes.data.uid })

        axios
          .get(
            process.env.GATSBY_RAILS_API_URL +
              `/users/${uidRes.data.uid}/tickets`
          )
          .then(res => {
            this.setState({ tickets: res.data, isLoading: false })
          })
      })
      .catch(err => {
        this.setState({ isLoading: false })
      })
  }

  handleChange(e) {
    if (e.target.name === 'firstName') {
      this.state.err.firstName = ''
    }
    if (e.target.name === 'lastName') {
      this.state.err.lastName = ''
    }
    if (e.target.name === 'email') {
      this.state.err.email = ''
    }
    if (e.target.name === 'phoneNumber') {
      this.state.err.phoneNumber = ''
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  submitForm(event) {
    event.preventDefault()
    const { firstName, lastName, email, phoneNumber } = this.state
    let self = this
    const err = {}
    if (firstName === '' || firstName.trim() === '') {
      err.firstName = 'Please enter your first name'
    }
    if (lastName === '' || lastName.trim() === '') {
      err.lastName = 'Please enter your last name'
    }
    if (email === '' || email.trim() === '') {
      err.email = 'Email is required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      err.email = 'Invalid email'
    }
    if (phoneNumber === '' || phoneNumber.trim() === '') {
      err.phoneNumber = 'Please enter your phone number'
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      window &&
        window.ga &&
        window.ga('send', 'event', {
          eventCategory: 'Sign Up',
          eventAction: 'Sign Up Submit Clicked',
          eventLabel: 'Sign Up Submit Clicked',
          transport: '1',
        })
      this.setState({ isLoading: true })
      axios
        .post(HOSTNAME + 'signup', {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber.replace(/\D/g, ''),
        })
        .then(function(response) {
          let res = response.data
          if (res.success) {
            self.setState({
              isLoading: false,
              resMessage: res.message,
              resType: 'alert alert-success',
            })
            let expires = new Date()
            expires.setHours(expires.getHours() + 24)
            expires = new Date(expires)
            cookies.set('user', res.data, { path: '/', expires })
            //cookies.set('Authorization', res.token, { path: '/', expires });
            setTimeout(function() {
              navigate('/')
            }, 200)
          } else {
            self.setState({
              isLoading: false,
              resMessage: res.message,
              resType: 'alert alert-danger',
            })
            setTimeout(function() {
              self.setState({ resMessage: '', resType: '' })
            }, 2000)
          }
        })
        .catch(function(error) {
          self.setState({
            isLoading: false,
            resMessage: error,
            resType: 'alert alert-danger',
          })
        })
    }
  }

  getDate(name) {
    let eventDate = '' 
    name = name ? (name).toLowerCase() : false
      if (name && name.indexOf('austin') !== -1) {
        eventDate = 'Oct 19-20'
      } else if (name && name.indexOf('brooklyn') !== -1) {
        eventDate = 'Nov 16-17'
      } else if (name && name.indexOf('washington') !== -1) {
        eventDate = 'Dec 14-15'
      } else if (name && name.indexOf('denver') !== -1) {
        eventDate = 'Sep 7-8'
      } else if (name && name.indexOf('boston') !== -1) {
        eventDate = 'Sep 28-29'
      }
    return eventDate
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      err,
      resMessage,
      resType,
      tickets,
      user,
      isLoading,
    } = this.state

    return (
      <div>
        {isLoading ? (
          <div className="loader__section">
            <div className="loading" />
          </div>
        ) : (
          <div id="wrappper" className="home__page page">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="events-container">
                    {/* <div className="event-denver">
                      <div className="event-denver__left-content">
                        <div className="event-denver__header">
                          <div className="event-denver__header--title">
                            <h1>Denver</h1>
                          </div>
                          <div className="event-denver__header--date">
                            <h4>Sep 7-8</h4>
                          </div>
                          <div className="event-denver__footer">
                            <div className="event-denver__footer--more-info">
                              <a
                                href="https://shopaf.co/pages/fall-denver-2019"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                More Info
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="event-denver__right-content--overlay" />
                      <div className="event-denver__right-content">
                        <div className="event-denver__ticket-cta">
                          {tickets.length > 0 ? (
                            <div className="event-denver__ticket-cta--claim">
                              <h6>Youre In!</h6>
                              <Link to={'/tickets'}>
                                <button className="btn ticket_btn view">
                                  View Tickets
                                </button>
                              </Link>
                              <a
                                href={`https://shopaf.co/pages/fall-denver-2019`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <button className="btn ticket_btn purchase">
                                  Purchase Tickets
                                </button>
                              </a>
                            </div>
                          ) : (
                            <div className="event-boston__ticket-cta--claim">
                              <h6>You Need a Ticket!</h6>

                              <a
                                href={`https://shopaf.co/pages/fall-denver-2019`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <button className="btn ticket_btn purchase">
                                  Purchase Tickets
                                </button>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="event-boston">
                      <div className="event-boston__left-content">
                        <div className="event-boston__header">
                          <div className="event-boston__header--title">
                            <h1>Boston</h1>
                          </div>
                          <div className="event-boston__header--date">
                            <h4>Sep 28-29</h4>
                          </div>
                          <div className="event-boston__footer">
                            <div className="event-boston__footer--more-info">
                              <a
                                href="https://shopaf.co/pages/fall-boston-2019"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                More Info
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="event-boston__right-content--overlay" />
                      <div className="event-boston__right-content">
                        <div className="event-boston__ticket-cta">
                          <div className="event-boston__ticket-cta--claim">
                            <h6>You Need a Ticket!</h6>

                            <a
                              href={`https://shopaf.co/pages/fall-boston-2019`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="btn ticket_btn purchase">
                                Purchase Tickets
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="event-austin">
                      <div className="event-austin__left-content">
                        <div className="event-austin__header">
                          <div className="event-austin__header--title">
                            <h1>Austin</h1>
                          </div>
                          <div className="event-austin__header--date">
                            <h4>{this.getDate('Austin')}</h4>
                          </div>
                          <div className="event-austin__footer">
                            <div className="event-austin__footer--more-info">
                              <a
                                href="https://shopaf.co/pages/fall-austin-2019"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                More Info
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="event-austin__right-content--overlay" />
                      <div className="event-austin__right-content">
                        <div className="event-austin__ticket-cta">
                          <div className="event-austin__ticket-cta--claim">
                            <h6>You Need a Ticket!</h6>

                            <a
                              href={`https://shopaf.co/pages/fall-austin-2019`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="btn ticket_btn purchase">
                                Purchase Tickets
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="event-brooklyn">
                      <div className="event-brooklyn__left-content">
                        <div className="event-brooklyn__header">
                          <div className="event-brooklyn__header--title">
                            <h1>Brooklyn</h1>
                          </div>
                          <div className="event-brooklyn__header--date">
                            <h4>{this.getDate('Brooklyn')}</h4>
                          </div>
                          <div className="event-brooklyn__footer">
                            <div className="event-brooklyn__footer--more-info">
                              <a
                                href="https://shopaf.co/pages/fall-brooklyn-2019"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                More Info
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="event-brooklyn__right-content--overlay" />
                      <div className="event-brooklyn__right-content">
                        <div className="event-brooklyn__ticket-cta">
                          <div className="event-brooklyn__ticket-cta--claim">
                            <h6>You Need a Ticket!</h6>

                            <a
                              href={`https://shopaf.co/pages/fall-brooklyn-2019`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="btn ticket_btn purchase">
                                Purchase Tickets
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="event-dc">
                      <div className="event-dc__left-content">
                        <div className="event-dc__header">
                          <div className="event-dc__header--title">
                            <h1>Washington D.C.</h1>
                          </div>
                          <div className="event-dc__header--date">
                            <h4>{this.getDate('Washington')}</h4>
                          </div>
                          <div className="event-dc__footer">
                            <div className="event-dc__footer--more-info">
                              <a
                                href="https://shopaf.co/pages/fall-washington-dc-2019"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                More Info
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="event-dc__right-content--overlay" />
                      <div className="event-dc__right-content">
                        <div className="event-dc__ticket-cta">
                          <div className="event-dc__ticket-cta--claim">
                            <h6>You Need a Ticket!</h6>

                            <a
                              href={`https://shopaf.co/pages/fall-washington-dc-2019`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="btn ticket_btn purchase">
                                Purchase Tickets
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Events
